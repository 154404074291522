import { Authenticator, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Paper, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Amplify from 'aws-amplify';
import React from 'react';
import './App.css';

const hr = window.location.href
let domain = "localhost";
let targetOpConsole = "http://localhost:3000";
let environ = "localdev";
if (hr.startsWith("https://ccreds.atlasreplymonitoring.it")) { targetOpConsole = "https://dev.operator.atlasreplymonitoring.it"; domain = "ccreds.atlasreplymonitoring.it"; environ = "collaudo"; }
else if (hr.startsWith("https://pcreds.atlasreplymonitoring.it")) { targetOpConsole = "https://operator.atlasreplymonitoring.it"; domain = "pcreds.atlasreplymonitoring.it"; environ = "production"; }

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: environ == "production" ? 'eu-west-1_OQM9flRgY' : 'eu-west-1_qLdcsOf1X',
        userPoolWebClientId: environ == "production" ? '40etn4u3fk5lk9p2gsespb1ev4' : 'o3ks80atojsmdsoh5687jdv3m',
        mandatorySignIn: false,
        authenticationFlowType: 'USER_SRP_AUTH',

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: domain,
            // domain: 'localhost',
            // // OPTIONAL - Cookie path
            //     path: '/',
            // // OPTIONAL - Cookie expiration in days
            //     expires: 365,
            // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            //     sameSite: "strict" | "lax",
            // // OPTIONAL - Cookie secure flag
            // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },

        // OPTIONAL - customized storage object
        // storage: MyStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        // oauth: {
        //     domain: 'auth.atlasreplymonitoring.it',
        //     // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin', 'dev.resources.atlasreplymonitoring.it/read','dev.resources.atlasreplymonitoring.it/write'],
        //     scope: ['phone', 'email', 'profile', 'openid', 'dev.resources.atlasreplymonitoring.it/read','dev.resources.atlasreplymonitoring.it/write'],
        //     redirectSignIn: 'http://localhost:3000/',
        //     redirectSignOut: 'http://localhost:3000/logout.html',
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        // }
    }
});


const theme = createTheme({
    palette: {
        danger: {
            main: '#e53e3e',
            dark: '#e53e3e'
        },
        primary: {
            main: '#0971f1',
            dark: '#053e85',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    }
});






function App() {

    return (

        <ThemeProvider theme={theme}>
            <Authenticator variation="modal" hideSignUp={true}>
                {

                    ({ signOut, user }) => {
                        console.log("!!!", user);
                        const groups = user?.getSignInUserSession().getAccessToken().payload["cognito:groups"] || [];
                        return (
                            <div className="App" style={{ height: '100vh' }}>
                                <div className="homeContainer" style={{ width: '100vw' }}>
                                    <Stack justifyContent="space-evenly" alignItems="center" style={{ width: '100vw', height: '100vh' }}>
                                        <img src="https://atlas-public-assets.s3.eu-west-1.amazonaws.com/canvas.png"
                                            style={{ maxWidth: "180px", maxHeight: "180px", "borderRadius": "5% 60% 5% 60%" }} />
                                        <div style={{ textAlign: "left", width: '35vw' }}>
                                            <Typography variant="h6">
                                                Ciao <strong>{user.username}</strong>,
                                            </Typography>
                                            <Typography>
                                                ti sei autenticato correttamente.
                                            </Typography>
                                        </div>
                                        {(groups.includes("operator") || groups.includes("admin") || groups.includes("ops") || groups.includes("dev")) ? (

                                            <div style={{ textAlign: "left", width: '35vw' }}>
                                                <Typography>
                                                    <a href={targetOpConsole}>Vai alla console operatore</a>
                                                </Typography>
                                            </div>
                                        ) : (
                                            <div style={{ textAlign: "left", width: '35vw' }}>
                                                <Typography>
                                                    Non sei abilitato alla console operatore. Se hai bisogno di questo accesso contatta un amministratore.
                                                </Typography>
                                            </div>
                                        
                                        )}
                                        <div style={{ textAlign: "left", width: '35vw' }}>
                                            <Typography>
                                                Vuoi cambiare la tua password? <br />
                                                Effettua il logout e segui la procedura "Forgot your password?".
                                            </Typography>
                                        </div>
                                        <div>
                                            <Button color="primary" variant="outlined" onClick={() => signOut()}>Logout</Button>
                                        </div>


                                    </Stack>

                                </div>

                            </div>
                        );
                        // if (user && (groups.includes("operator") || groups.includes("admin") || groups.includes("ops"))) {

                        // } else return <div style={{ margin: "15px" }}>
                        //     <h1>Ops... utente riconosciuto ma i permessi non sono sufficienti.</h1>
                        //     <p>Ecco alcune possibili soluzioni:</p>
                        //     <ul>
                        //         <li><a href="#" onClick={signOut}>Cambia utente</a></li>
                        //         <li><a href="mailto:info@atlasreplymonitoring.it">Richiedi accesso</a></li>
                        //     </ul>
                        // </div>
                    }
                }
            </Authenticator>
        </ThemeProvider>

    );
}
export default App;
